/**
 * @商品相关
 * 2022-08-03 kadiam
*/
// import bossApi from '../utils/bossApi'
import bossXypq from '../utils/bossXypq'

// 商品搜索 热门搜索
export function mallProduct_hotSearch() {
  return bossXypq({ url: 'hx.baoxian/mallProduct/hotSearch', method: 'GET' })
}
// 商品搜索 首页商品名称查询
export function mallProduct_searchTagList(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/searchTagList', method: 'POST', data })
}

// 商品详情
export function mallProduct_mallProductDetailH(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/mallProductDetailH?productId=' + data, method: 'GET' })
}
// 商品详情
export function mallProduct_mallProductDetailH_VIP(data) {
  return bossXypq({ url: 'hx.baoxian/mallVipProduct/detail?mallVipProductId=' + data, method: 'GET' })
}
// 商品到货提醒-查询
export function mallProduct_getArrivalReminder(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/getArrivalReminder', method: 'POST', data })
}
// 商品到货提醒-设置
export function mallProduct_setArrivalReminder(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/setArrivalReminder', method: 'POST', data })
}
// 商品新增收藏
export function user_collection(data) {
  return bossXypq({ url: 'hx.baoxian/user/collection/add', method: 'POST', data })
}
// 用户收藏-根据主键删除
export function user_collection_delete(data) {
  return bossXypq({ url: 'hx.baoxian/user/collection/delete/' + data, method: 'GET' })
}
// 用户收藏-批量删除
export function user_collection_deleteAll(data) {
  return bossXypq({ url: 'hx.baoxian/user/collection/deleteAll', method: 'POST', data })
}
// 首页商品分类品牌查询
export function searchMallProductCategoryBrand(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/searchMallProductCategoryBrand', method: 'POST', data })
}
/**
 * @商品分类
 * 2022-08-12 kadiam
*/
// 通过id查询第三级别所有列表
export function productMallCategory_boss_generations(data) {
  return bossXypq({ url: 'hx.baoxian/productMallCategory/boss/generations?pid=' + data, method: 'GET' })
}
// 通过id查询下级分类查询
export function productMallCategory_boss_list(data) {
  return bossXypq({ url: 'hx.baoxian/productMallCategory/boss/list', method: 'POST', data })
}

/**
 * @商品推荐
 * 2023-03-08 kadiam
*/
// 商品推荐-标签列表
export function mallProduct_tagList(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/tagList', method: 'POST', data })
}
// 商品推荐-首页商品查询（商品推荐列表）
export function searchMallProductRecommend(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/searchMallProductRecommend', method: 'POST', data })
}
// 商品推荐-标签分类-子分类查询
export function tagCategoryList(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/tagCategoryList', method: 'POST', data })
}

/**
 * @特惠卖
 * 2024-05-21 kadiam
*/
// 通过id查询分类所有列表
export function productModule_detail(data) {
  return bossXypq({ url: 'hx.baoxian/productModule/detail', method: 'POST', data })
}
// 通过id查询分类所有列表
export function mallPreferential_categoryList(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/categoryList', method: 'POST', data })
}
// 购物车商品种类数量
export function mallPreferential_cartCalculate(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/cartCalculate', method: 'POST', data })
}
// 商品列表
export function mallPreferential_productList(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/productList', method: 'POST', data })
}
// 商品详情
export function mallPreferential_productDetail(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/productDetail?productId=' + data, method: 'GET' })
}
// 添加购物车
export function mallPreferential_addCart(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/addCart', method: 'POST', data })
}
// 删除购物车
export function mallPreferential_delCart(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/delCart', method: 'POST', data })
}
// 订单预览
export function mallPreferential_orderPreview(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/orderPreview', method: 'POST', data })
}
// 订单预览
export function mallPreferential_brandList(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/brandList', method: 'POST', data })
}
